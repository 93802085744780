:root {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    --secondary: #87cfebc5;
    --secondary-light: #87cfeba4;
    --text-color: gray;
}

* {
    padding: 0;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    font: 1.8em/2 "poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "poppins", sans-serif;
    line-height: 32px;
    color: rgb(53, 53, 53);
    font-weight: 500;
    overflow-x: hidden;
    overflow-x: hidden;
    background: url(../images/others/final_bg.jpg), #3bd8ff7c;
    background-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
}

#app {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

#snow-canvas {
    position: fixed;
    min-height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

a {
    background-color: transparent;
}

a:active,
a:hover {
    outline: 0;
}

nav ol,
nav ul {
    list-style: none;
}

.d-none {
    display: none !important;
}

.sec-heading-wrapper {
    text-align: center;
    width: 100%;
}

::-moz-selection {
    background-color: #1f97e7;
    color: #fff;
}

::selection {
    background-color: #1f97e7;
    color: #fff;
}

.sec-heading {
    line-height: 59px;
    color: #fffff1;
    text-transform: uppercase;
    font-size: 48px;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    text-shadow: 0px 1px 4px rgba(47, 152, 222, 0.562);
    margin-bottom: 50px;
}

.sec-heading span {
    text-transform: lowercase;
    margin: 0;
    padding: 0;
    display: inline;
}

.text-left {
    text-align: left;
}


/* scrollbar */

::-webkit-scrollbar {
    width: 12px;
    height: 7px;
    background: #87cfebfb;
}

::-webkit-scrollbar-thumb {
    background-color: #0c4368;
}


/*  web loader */

.loader-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 111;
    background-color: rgba(0, 0, 0, 0.082);
}

.fixed-loading-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999999;
    background-color: rgba(0, 0, 0, 0.048);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid var(--secondary);
    border-radius: 50%;
    width: 85px;
    height: 85px;
    -webkit-animation: spin 0.5s linear infinite;
    animation: spin 0.5s linear infinite;
    overflow: hidden;
    position: relative;
    -webkit-box-shadow: 0px 0px 15px #00000065;
    box-shadow: 0px 0px 15px #00000065;
}

.loader-wrapp {
    margin: 0 auto;
    background-color: var(--light);
    outline-offset: -3px;
    position: relative;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    overflow: hidden;
    -webkit-box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.152);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.152);
}

.loader-logo {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.loader-logo img {
    margin-top: 8px;
    padding: 3px;
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    max-width: 120px;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


/* go to top */

.go-to-top {
    position: fixed;
    bottom: 25px;
    right: 15px;
    background-color: var(--secondary);
    border: 2px solid #0c4368;
    color: rgb(15, 53, 85);
    border-radius: 7px;
    font-size: 55px;
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    z-index: 99;
    -webkit-box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.178);
    box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.178);
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    -o-transition: transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
    visibility: hidden;
    -webkit-transform: translateX(150%);
    -ms-transform: translateX(150%);
    transform: translateX(150%);
}

.arrow-up {
    font-size: 23px !important;
}

.show-top {
    visibility: visible;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

.about-img {
    width: 100%;
}

h2 {
    font-size: 4.6rem;
    line-height: 5.4rem;
    font-weight: 700;
    margin-bottom: 35px;
}

h3 {
    font-size: 3rem;
    line-height: 3.3rem;
}

h4 {
    font-size: 2.2rem;
    line-height: 2.6rem;
}

a {
    color: var(--secondary);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

p {
    margin: 30px 0 0;
}

.bold {
    font-weight: 700;
}

.container {
    margin: 0 auto;
    width: 100%;
    max-width: 1160px;
    padding: 0 18px;
}

.cta {
    padding: 16px 30px;
    background: var(--secondary);
    text-decoration: none;
    font-weight: 700;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.disable {
    display: none;
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

#about {
    padding-top: 15px;
}

.version-wrapper {
    margin: 25px auto;
    width: max-width;
}

.version-wrapper form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    border: 3px solid #0f5e8f;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 0 auto;
    background-color: #55bed0;
    max-width: 250px;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.version-wrapper form:hover {
    -webkit-transform: scale(1.04);
    -ms-transform: scale(1.04);
    transform: scale(1.04);
}

.version-wrapper form input {
    border: none;
    outline: none;
    background-color: #55bed0;
    padding: 8px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    max-width: 100px;
    height: 100%;
}

.mint-area-disabled {
    pointer-events: none;
    opacity: 0.3;
    user-select: none;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type="number"] {
    -moz-appearance: textfield;
}

.version-wrapper form .qtn-btn {
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    background-color: #116fad;
    background-color: #116fad;
    min-width: 22px;
    height: 100%;
    font-size: 22px;
    font-weight: 900;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    padding: 3px 15px 8px 15px;
}

.mint-now- {
    -webkit-transition: 400ms linear;
    -o-transition: 400ms linear;
    transition: 400ms linear;
    color: #fff;
    background-color: var(--secondary);
    padding: 7px 2rem;
    font-size: 18px;
    outline: 0;
    border: 0;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 0 0.5rem rgb(0 0 0 / 30%);
    box-shadow: 0 0 0.5rem rgb(0 0 0 / 30%);
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 25px auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 650;
    text-transform: uppercase;
    text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.432);
    text-decoration: none;
    color: #fff;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.mint-now-:hover {
    -webkit-transform: scale(1.04);
    -ms-transform: scale(1.04);
    transform: scale(1.04);
}

.dbl-icon {
    font-size: 24px !important;
    margin-left: 4px;
}


/*  */

.about-text {
    width: 85%;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    margin-bottom: 55px;
}

.story-description {
    width: 85%;
    margin: 10px auto;
    text-align: left;
}

.story-description strong {
    color: #0c4368;
}

.story-description a {
    color: #fff;
}

.counter-wrapper {
    width: 100%;
    position: absolute;
    top: 56%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    right: 0;
    z-index: 2;
}

.launch-date {
    margin: 25px auto 15px auto;
    text-align: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.launch-date h3 {
    color: #0c4368;
    /* color: var(--secondary); */
    font-size: 36px;
    text-shadow: 0px 0px 1px #000;
    margin-top: 0px;
    margin-bottom: 8px;
}

.hero-counter {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 35px auto;
}

.counter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    background: #0f5e8f;
    border-radius: 4px;
    padding: 6px 3px 4px 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.counter-item {
    margin: 0 3px;
    text-align: center;
}

.counter--label {
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    color: rgb(243, 239, 239);
}

.counter--value {
    background: rgb(156 207 215 / 49%);
    border-radius: 4px;
    width: 65px;
    height: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
}

.counter--value h5 {
    font-weight: 900;
    font-size: 35px;
    color: rgb(243, 239, 239);
}

.description .side-join-btn {
    display: none;
}

.about-socials {
    border: none !important;
    padding: 0 !important;
    margin-top: 20px !important;
}

.about-socials .link {
    margin: 0 15px !important;
}

.about-socials.sidebar-socials .link a,
.about-socials.sidebar-socials .link {
    border: 1px solid #116fad;
    width: 38px;
    height: 38px;
    border-radius: 4px;
    color: transparent;
    color: transparent;
}

.about-socials.sidebar-socials .insta {
    background-color: #fff;
}

.about-socials.sidebar-socials .twitter {
    background-color: #fff;
}

.about-socials.sidebar-socials .discord {
    background-color: #fff;
}

.about-socials.sidebar-socials .telegram {
    background-color: #fff;
}

.about-socials.sidebar-socials .x {
    background-color: #fff;
}

/*  story */

#story {
    padding: 20px 0 15px 0;
    text-align: center;
}

#story .container {
    padding: 15px 0 0px 0;
}


/*  */

#nft {
    padding-top: 50px;
    padding-bottom: 140px;
    overflow-x: hidden;
    height: 100%;
}

.nft-slide-container,
.nft-slide-container.swiper-comtainer {
    overflow: visible !important;
}

.nft-slide {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.nft-art-img {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin: 30px 15px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: -webkit-grab;
    cursor: grab;
    border-radius: 12px;
}

.swiper-slide-active .nft-art-img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.swiper-pagination {
    width: 100%;
    left: 0 !important;
}

.swiper-pagination-bullet {
    width: 35px !important;
    height: 3px !important;
    background: #116fad !important;
    margin: 1px 4px !important;
    -webkit-transition: all 0.3s ease-in-out !important;
    -o-transition: all 0.3s ease-in-out !important;
    transition: all 0.3s ease-in-out !important;
    border-radius: 0 !important;
    opacity: 1 !important;
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    left: 0;
    width: 100%;
    top: 110% !important;
    bottom: unset !important;
}

.swiper-pagination-bullet-active {
    background: #0c4368 !important;
}

@media (max-width: 780px) {
    .desktopdesc {
        display: none;
    }
    .about-socials {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .about-text,
    .story-description {
        width: 90%;
    }
    #about {
        padding-top: 12px;
        margin-bottom: 10px;
    }
    #story {
        padding: 30px 0 20px 0;
    }
    #story .container {
        padding: 0px 0 0px 0;
    }
    .sec-heading {
        margin-bottom: 12px;
    }
    #nft {
        padding-top: 12px;
    }
}

@media (max-width: 670px) {
    .container {
        padding: 0 15px;
    }
    #nft {
        padding-bottom: 30px;
        width: 99%;
        overflow-x: hidden;
        height: 100%;
    }
    #nft .swiper-pagination {
        display: none !important;
    }
    .nft-slide {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .nft-art-img {
        width: 100% !important;
        margin: 0 auto !important;
        max-width: 300px;
        margin: 0 auto;
    }
    .swiper-slide-active .nft-art-img {
        -webkit-transform: unset;
        -ms-transform: unset;
        transform: unset;
    }
    .swiper-pagination-bullet {
        width: 25px !important;
        height: 3px !important;
    }
}

@media (max-width: 500px) {
    .sec-heading {
        font-size: 32px;
    }
    .story-description {
        margin-top: 10px auto;
        font-size: 18px;
        line-height: 25px;
    }
    .version-wrapper form .qtn-btn {
        font-size: 25px;
        padding: 3px 15px 8px 15px;
    }
    .version-wrapper form input {
        padding: 5px 12px;
    }
    .counter-wrapper {
        width: 100%;
        position: absolute;
        top: 56%;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        right: 0;
        z-index: 2;
    }
    .launch-date {
        margin: 0 auto;
        text-align: center;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        margin-top: -12px;
        margin-bottom: 8px;
    }
    .launch-date h3 {
        font-size: 36px;
        text-shadow: 0px 2px 5px #000;
        margin-top: 30px;
        margin-bottom: 15px;
    }
    .counter--label {
        font-size: 11px;
    }
    .counter--value {
        width: 50px;
        height: 70px;
    }
    .counter--value h5 {
        font-size: 27px;
    }
}

@media (max-width: 320px) {
    .launch-date {
        margin: 0 auto;
        text-align: center;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        margin-top: -12px;
        margin-bottom: 8px;
    }
    .launch-date h3 {
        font-size: 29px;
    }
    .counter--label {
        font-size: 9px;
    }
    .counter--value {
        width: 45px;
        height: 60px;
    }
    .counter--value h5 {
        font-size: 24px;
    }
}