#team {
    padding: 15px 0;
}

.team-wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
    margin: 25px auto;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.team-card {
    border-radius: 14px;
    width: 230px;
    margin: 12px 8px;
    text-align: center;
    background: var(--secondary);
    color: #fff;
    -webkit-transition: .3s ease;
    -o-transition: .3s ease;
    transition: .3s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 15px;
}

.team-card:hover {
    -webkit-transform: scale(1.03) !important;
    -ms-transform: scale(1.03) !important;
    transform: scale(1.03) !important;
}

.team-card img {
    width: 100%;
    max-width: 230px;
    min-height: 230px;
    height: auto;
    border-radius: 12px;
    border: 4px solid var(--secondary);
    background-color: var(--secondary);
    color: #fff;
    text-align: center;
    font-size: 22px;
    margin: 0 !important;
}

.team-card h2 {
    font-weight: 900 !important;
    margin: 0 auto!important;
    padding: 12px 3px 7px 3px;
    line-height: 27px !important;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: block;
    font-size: 27px;
    margin-top: auto;
    color: rgb(54, 54, 54);
}

.team-card p {
    padding: 0 8px;
    display: block;
    margin: 0 !important;
    line-height: 25px;
    font-size: 14px;
}

.role- {
    font-size: 17px !important;
    font-weight: 700;
}

.member-card-body {
    width: 96%;
    margin: 0 auto;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    border-radius: 12px;
}

.member-about {
    padding-bottom: 18px !important;
}

.team-socials {
    padding: 0;
    padding-top: 7px;
    margin: 0;
    margin-top: auto;
}

.team-socials .team-social-link {
    border: 1px solid #fff;
    width: 39px;
    height: 39px;
    margin-right: 15px;
    -webkit-box-shadow: inset 0 0 0 #fff;
    box-shadow: inset 0 0 0 #fff;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.team-socials .team-social-link:hover {
    -webkit-box-shadow: inset 0 -54px 0 #fff;
    box-shadow: inset 0 -54px 0 #fff
}

.team-socials .team-social-link:hover .sicail-sq-icon {
    background-color: var(--secondary);
}

.team-socials .team-social-link a {
    width: 39px;
    height: 39px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.team-socials .ig_icon {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/instagram.svg) no-repeat center;
    mask: url(../../assets/images/others/instagram.svg) no-repeat center
}

.team-socials .linkedin {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/linkedin.svg) no-repeat center;
    mask: url(../../assets/images/others/linkedin.svg) no-repeat center
}

.team-socials .insta {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/instagram.svg) no-repeat center;
    mask: url(../../assets/images/others/instagram.svg) no-repeat center
}

.team-socials .twitter {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/twitter.svg) no-repeat center;
    mask: url(../../assets/images/others/twitter.svg) no-repeat center
}

.team-socials .x {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/x.svg) no-repeat center;
    mask: url(../../assets/images/others/x.svg) no-repeat center
}

.team-socials .opensea {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/opensea.svg) no-repeat center;
    mask: url(../../assets/images/others/opensea.svg) no-repeat center
}

.team-socials .discord {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/discord.svg) no-repeat center;
    mask: url(../../assets/images/others/discord.svg) no-repeat center
}

.team-socials .telegram {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/telegram.svg) no-repeat center;
    mask: url(../../assets/images/others/telegram.svg) no-repeat center
}

@media screen and (max-width:768px) {
    #team {
        padding: 8px 0;
        margin-top: 0;
    }
}

@media screen and (max-width:500px) {
    .team-wrapper {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        max-width: 100%;
        width: 100%;
        padding: 12px;
        margin: 10px auto;
    }
    .team-card {
        width: 49%;
        margin: 8px 0;
        padding-bottom: 7px;
    }
    .team-card img {
        width: 100%;
        -o-object-fit: contain;
        object-fit: contain;
        min-height: unset;
        height: auto;
        width: 100%;
        min-width: 100%;
        font-size: 16px;
    }
    .team-card h2 {
        font-size: 24px;
        white-space: normal;
        padding: 4px;
    }
    .role- {
        font-size: 17px !important;
    }
    .team-card p {
        font-size: 15px;
        line-height: 20px;
        white-space: normal;
        padding: 4px;
    }
}

@media screen and (max-width:355px) {
    .team-card {
        width: 90%;
        margin: 8px auto;
        padding-bottom: 7px;
    }
}