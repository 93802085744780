#faq {
    padding: 25px 0;
}

.faq-wrapper {
    margin: 22px auto;
    border-radius: 12px;
    border: 3px solid var(--secondary);
    overflow: hidden;
    color: #fff;
    -webkit-transition: .4s linear;
    -o-transition: .4s linear;
    transition: .4s linear;
    background-color: rgb(11, 85, 114);
}

.faq-qs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 14px 16px 12px 16px;
    cursor: pointer;
    -webkit-box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.159);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.159);
}

.faq-qs h6 {
    font-weight: 700;
    font-size: 16px;
    width: 98%;
    padding-right: 8px;
}

.faqs-answer {
    height: 0;
    overflow: hidden;
    padding: 0 15px;
    font-weight: 600;
    -webkit-transition: .4s linear;
    -o-transition: .4s linear;
    transition: .4s linear;
}

.active-faq .faqs-answer {
    padding: 0 15px 15px 15px;
    height: auto;
    border-top: 2px solid var(--secondary);
}

.faq-arrow * {
    font-size: 16px !important;
}

.faq-arrow {
    background-color: var(--secondary);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    -o-transition: transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
    padding: 4px;
    color: #fff;
}

.active-faq .faq-arrow {
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    margin: 0;
    padding: 0;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

@media screen and (max-width:768px) {
    #faq {
        margin-top: 0;
        padding: 8px 0;
    }
}

@media screen and (max-width:400px) {
    .faqs-answer p,
    .faq-qs h6 {
        font-size: 13px;
    }
}