.web-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 999;
    color: #fff;
    border: none;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.096);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.096);
    background: -o-linear-gradient(345deg, rgba(93, 181, 192, 0.911) 2%, rgba(11, 145, 163, 0.884) 56%);
    background: linear-gradient(105deg, rgba(93, 181, 192, 0.911) 2%, rgba(11, 145, 163, 0.884) 56%);
}

.header-container {
    position: relative;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 80px;
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
}

.header-spacer {
    width: 100vw;
    height: 70px;
}

.header-logo-area {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
}

.header-logo-area .nav-items {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    background: transparent;
    outline: none;
}

.header-main-logo {
    font-weight: 750;
    font-size: 22px;
    z-index: 11;
    padding: 14px 0 14px 17px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.header-logo-area img {
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    max-width: 175px;
    margin-top: auto;
    cursor: pointer;
    margin-top: 8px;
}

.header-right-area {
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    min-height: 99px;
    z-index: 111;
    padding-right: 17px;
    padding-left: 25px;
}

.header-join-btn {
    position: relative;
    -webkit-transition: 400ms linear;
    -o-transition: 400ms linear;
    transition: 400ms linear;
    color: #fff;
    background-color: var(--secondary);
    padding: 0 2rem;
    min-height: 35px;
    font-size: 18px;
    outline: 0;
    border: 0;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
    cursor: pointer;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 650;
    text-transform: uppercase;
    text-shadow: 2px 2px 15px #000;
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    text-decoration: none;
    color: #fff;
    width: auto;
}

.header-join-btn:hover {
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.418);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.418);
    -webkit-transform: scale(1.0);
    -ms-transform: scale(1.0);
    transform: scale(1.0);
    color: #fff;
}

.header-join-btn img {
    color: #fff;
    margin-left: 7px;
    width: 25px;
    pointer-events: none;
}

.menu-btn {
    margin-left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.menu-icon {
    background-color: var(--secondary);
    border-radius: 6px;
    cursor: pointer;
    font-size: 25px !important;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    padding: 2px 6px;
}

.side-bar-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    /* ff 3.6+ */
    /* safari 5.1+,chrome 10+ */
    /* opera 11.10+ */
    background: -o-linear-gradient(105deg, rgba(115, 198, 209) 2%, rgba(11, 145, 163) 56%);
    /* ie 6-9 */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1EB2C5', endColorstr='#A7F3FF', GradientType=0);
    /* ie 10+ */
    /* global 94%+ browsers support */
    background: -o-linear-gradient(345deg, rgb(115, 198, 209) 2%, rgb(11, 145, 163) 56%);
    background: linear-gradient(105deg, rgb(115, 198, 209) 2%, rgb(11, 145, 163) 56%);
    height: 100vh;
    overflow-y: auto;
    overflow-X: hidden;
    z-index: 9999;
    min-width: 350px;
    -webkit-box-shadow: 0 0 15px #000;
    box-shadow: 0 0 15px #000;
    -webkit-transform: translate(150%);
    -ms-transform: translate(150%);
    transform: translate(150%);
    -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
    transition: -webkit-transform 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
    -o-transition: transform 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
    transition: transform 0.7s cubic-bezier(0.39, 0.575, 0.565, 1);
    transition: transform 0.7s cubic-bezier(0.39, 0.575, 0.565, 1), -webkit-transform 0.7s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.opened-sidebar {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
}

.side-logo {
    width: 100%;
    min-height: 80px;
    max-height: 120px;
    padding: 5px 15px 5px 15px;
    text-align: center;
    border-bottom: .3px solid rgba(255, 255, 255, 0.13);
    -webkit-box-shadow: 0 0 15px #000;
    box-shadow: 0 0 15px #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.side-logo img {
    width: 100%;
    margin: 0 auto;
    max-width: 140px;
}

.side-bar-wrapper ul {
    width: 90%;
    min-height: 50%;
    max-height: 80%;
    overflow: auto;
    padding: 22px 8px 0 8px;
    margin: 5px auto;
}

.side-bar-wrapper ul::-webkit-scrollbar {
    width: 7px;
    background: transparent;
}

.side-bar-wrapper ul::-webkit-scrollbar-track {
    border-radius: 50px;
}

.side-bar-wrapper ul::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 50px;
}

.side-bar-wrapper ul li .header-join-btn {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 0 auto;
}

.side-bar-wrapper ul li .nav-items:not(.header-join-btn) {
    display: block;
    padding: 4px 8px;
    margin: 8px auto;
    text-align: center;
    font-weight: 800;
    font-size: 17px;
    text-decoration: none;
    color: #0c4368;
    ;
    -webkit-transition: .2s linear;
    -o-transition: .2s linear;
    transition: .2s linear;
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
    background: transparent !important;
    outline: none;
    border: none;
}

.side-bar-wrapper ul li .nav-items:hover:not(.header-join-btn) {
    color: #000;
}

.backdrop-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.24);
    z-index: 99;
}

.sidebar-close-btn {
    position: absolute;
    top: 50%;
    left: -2%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: var(--secondary);
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer !important;
    z-index: 666;
}

.chev-icon {
    font-size: 48px !important;
}

.sidebar-open-btn {
    position: fixed;
    top: 50%;
    right: -2%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: var(--secondary);
    width: 60px;
    height: 55px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    -webkit-box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.233);
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.233);
}

.sidebar-open-btn .chev-icon {
    font-size: 35px !important
}

.sidebar-socials {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    padding-top: 25px;
    border-top: 1px solid rgba(255, 255, 255, 0.13);
}

@media (min-width:992px) {
    .side-bar-wrapper {
        position: relative;
        top: 0;
        right: 0;
        background: transparent;
        height: auto;
        z-index: unset;
        min-width: auto;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
    .side-logo {
        display: none;
    }
    .side-bar-wrapper ul {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        min-height: auto;
        max-height: 100%;
        padding: 0;
        margin: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-right: 18px;
    }
    .side-bar-wrapper ul .nav-items {
        font-weight: 700 !important;
        font-size: 20px !important;
    }
    .side-bar-wrapper ul li .header-join-btn {
        /* display: none */
        margin-left: 18px;
    }
    .backdrop-sidebar {
        display: none;
    }
    .sidebar-close-btn {
        display: none
    }
    .sidebar-open-btn {
        display: none
    }
    .sidebar-socials {
        display: none;
    }
    .header-container {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        overflow: hidden;
    }
    .header-right-area {
        display: none
    }
}

@media (max-width:768px) {
    .sidebar-open-btn {
        border-radius: 12px;
        right: -22px;
        width: 55px;
    }
}

@media (max-width:500px) {
    .sidebar-open-btn,
    .header-join-btn:not(.side-join-btn) {
        font-size: 14px !important;
    }
    .header-right-area {
        margin-left: 0;
        padding-left: 0px;
        min-height: 76px;
        position: absolute;
        top: 0;
        right: 0;
    }
    .header-logo-area {
        width: 100%;
        height: 75px;
        overflow: hidden;
        padding: 0;
    }
    .header-logo-area img {
        margin-top: 15px;
        margin-left: -15px;
        -o-object-fit: contain;
        object-fit: contain;
        width: 100%;
        max-width: 125px;
    }
    .sidebar-close-btn {
        top: 46px;
        left: 17px;
        border-radius: 8px;
    }
    .side-bar-wrapper ul li .nav-items {
        font-size: 21px;
    }
    .header-main-logo {
        padding: 7px 16px !important;
    }
    .header-container {
        padding-left: 0;
    }
    .side-logo {
        padding-top: 15px;
        padding-left: 45px;
    }
    .side-logo img {
        max-width: 140px;
    }
}

@media (max-width:390px) {
    .header-join-btn.head2-join-btn {
        font-size: 12px !important;
        padding: 6px;
        min-height: auto;
        line-height: 1;
    }
}

@media (max-width:370px) {
    .side-bar-wrapper {
        min-width: 78%;
    }
    .side-bar-wrapper ul li .nav-items {
        font-size: 20px;
    }
}

@media (max-width:340px) {
    .side-bar-wrapper {
        min-width: 88%;
    }
    .side-bar-wrapper ul li .nav-items {
        font-size: 17px;
    }
}

@media (max-width:338px) {
    .header-logo-area img {
        max-width: 105px;
    }
    .header-join-btn:not(.side-join-btn) {
        font-size: 14px;
        padding: 6px;
        min-height: auto;
        line-height: 1;
    }
    .header-join-btn.head2-join-btn {
        font-size: 10px !important;
        padding: 6px;
    }
}