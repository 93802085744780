.footer {
    border-top: 1px solid var(--secondary);
    background: rgba(0, 0, 0, 0.096);
    -webkit-backdrop-filter: blur(2px) saturate(80%);
    backdrop-filter: blur(2px) saturate(80%);
}

.footer .ft-top-sec {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 35px auto 15px auto
}

.footer .infos {
    width: 44.44%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.footer .picto img {
    width: 100%;
    width: 180px;
}

.footer .typo {
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    margin-left: 20px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.footer .baseline {
    font-weight: 800;
    color: #fff;
    margin-top: 10px
}

.footer .copyright {
    font-size: 1.5rem;
    color: #848198;
    font-weight: 300;
    margin-top: 40px;
    display: block;
    width: 100%;
    margin-left: auto;
    text-align: right;
    padding-bottom: 7px;
    border-bottom: 1px solid #84819849;
}

.footer .right {
    width: 22.22%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.footer .nav a {
    text-decoration: none;
    font-size: 1.6rem;
    color: #4e619a;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.footer .nav a:hover {
    color: var(--secondary)
}

.sidebar-socials .link,
.footer .link {
    width: 38px;
    height: 38px;
    margin-right: 15px;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    color: transparent;
    border-radius: 4px;
}

.sidebar-socials .link:hover,
.footer .link:hover {
    transform: scale(1.07);
}

.footer .link:last-child {
    margin-right: 0
}

.sidebar-socials .link a,
.footer .link a {
    width: 38px;
    height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: transparent;
}

.insta-link {
    box-shadow: inset 0 -54px 0 #E1306C;
}

.twitter-link {
    box-shadow: inset 0 -54px 0 #30a0e1;
}

.x-link {
    box-shadow: inset 0 -54px 0 #000000;
}

.disco-link {
    box-shadow: inset 0 -54px 0 #3a4af1;
}

.teleg-link {
    box-shadow: inset 0 -54px 0 #0088cc;
}

.sidebar-socials .ig_icon,
.footer .ig_icon {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/ig.svg) no-repeat center;
    mask: url(../../assets/images/others/ig.svg) no-repeat center
}

.sidebar-socials .linkedin,
.footer .linkedin {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/linkedin.svg) no-repeat center;
    mask: url(../../assets/images/others/linkedin.svg) no-repeat center
}

.sidebar-socials .insta,
.footer .insta {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/instagram.svg) no-repeat center;
    mask: url(../../assets/images/others/instagram.svg) no-repeat center
}

.sidebar-socials .twitter,
.footer .twitter {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/twitter.svg) no-repeat center;
    mask: url(../../assets/images/others/twitter.svg) no-repeat center
}

.sidebar-socials .x,
.footer .x {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/x.svg) no-repeat center;
    mask: url(../../assets/images/others/x.svg) no-repeat center
}

.sidebar-socials .opensea,
.footer .opensea {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/opensea.svg) no-repeat center;
    mask: url(../../assets/images/others/opensea.svg) no-repeat center
}

.sidebar-socials .discord,
.footer .discord {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/discord.svg) no-repeat center;
    mask: url(../../assets/images/others/discord.svg) no-repeat center
}

.sidebar-socials .telegram,
.footer .telegram {
    background-color: #fff;
    -webkit-mask: url(../../assets/images/others/telegram.svg) no-repeat center;
    mask: url(../../assets/images/others/telegram.svg) no-repeat center
}

.footer-terms {
    padding: 24px 0 12px 0;
}

.footer-terms p {
    margin: 12px 0;
    font-size: 16px;
}

.team-socials .link {
    border: 1px solid #fff;
    border-radius: 8px;
    width: 38px;
    height: 38px;
}

.team-socials .link a {
    width: 38px;
    height: 38px;
}

@media screen and (max-width: 960px) {
    .footer .infos {
        width: auto;
        padding-right: 30px
    }
    .footer .baseline {
        margin-top: 20px;
        line-height: 2.5rem
    }
    .footer .copyright {
        line-height: 2rem;
    }
    .footer .right {
        width: auto
    }
}

@media screen and (max-width: 670px) {
    .footer .link {
        margin-right: 5px
    }
}

@media screen and (max-width: 500px) {
    .footer .ft-top-sec {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin: 35px auto 20px auto
    }
    .footer .right {
        margin-bottom: 40px
    }
    .footer .right .link {
        margin: 0 20px
    }
    .footer .infos {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        padding-right: 0;
        text-align: center;
    }
    .footer .picto {
        margin: 0 auto;
    }
    .footer .social {
        margin-top: 0;
        text-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .footer .nav {
        display: none
    }
    .footer .copyright {
        text-align: center;
    }
    .footer a {
        width: calc(50% - 15px);
        overflow: hidden
    }
    .footer-terms h3 {
        font-size: 22px;
        text-align: center;
    }
    .footer-terms p {
        margin: 10px 0;
        text-align: center;
        font-size: 13px !important;
    }
}