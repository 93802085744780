.rd-timeline {
    position: relative;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    margin-top: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

#roadmap {
    position: relative;
    padding: 25px 0;
}

.big_dolphin {
    position: absolute;
    top: 90%;
    transform: translate(-50%, -50%);
    left: 45%;
    width: 100%;
    max-width: 750px;
    z-index: -1;
    pointer-events: none;
    user-select: none;
}

.timeline-left {
    width: 50%;
}

.timeline-right {
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.timeline-right img {
    width: 100%;
    border-radius: 8px;
    margin: 20px 0;
    -webkit-box-shadow: 2px 2px 15px rgb(0, 0, 0);
    box-shadow: 2px 2px 15px rgb(0, 0, 0);
    max-width: 300px;
    min-width: 300px;
    height: 300px;
    -webkit-transition: .3s ease;
    -o-transition: .3s ease;
    transition: .3s ease;
    border: none;
    outline: none;
    text-align: center;
    font-size: 30px;
    background: var(--secondary);
    color: #fff;
    overflow: hidden;
}

.timeline-right img:hover {
    -webkit-transform: translateY(-4px) scale(1.03) !important;
    -ms-transform: translateY(-4px) scale(1.03) !important;
    transform: translateY(-4px) scale(1.03) !important;
}

.container-map {
    position: relative;
    padding: 25px 0 15px 0;
}

.container-map::before {
    content: '';
    position: absolute;
    width: 6px;
    background: var(--secondary);
    top: 0;
    left: 0;
    border-radius: 50px;
    height: 102%;
}

.roadmap-section-heading {
    position: relative;
    padding-left: 34px;
    font-weight: 900;
    font-size: 27px;
}

.roadmap-section-heading::before {
    content: '';
    position: absolute;
    width: 26px;
    height: 25px;
    top: 10%;
    left: -2.4%;
    background: #0c4368;
    border: 4px solid var(--secondary);
    border-radius: 50%;
    background-size: 100%;
    z-index: 1;
}

.container-map ul {
    list-style: none;
    margin-top: 10px;
}

.date-about-roadmap {
    padding-left: 37px;
    font-size: 16px;
    color: var(--text);
}

.container-map li,
.container-map p {
    padding-left: 40px;
    margin: 0 !important;
    font-size: 17px;
    font-weight: 600;
}

.container-map a {
    color: #ffffff;
}

@media Screen and (max-width: 768px) {
    #roadmap {
        padding: 8px 0;
    }
    .big_dolphin {
        top: unset;
        bottom: 14%;
        left: 75%;
        max-width: 350px;
    }
    .rd-timeline {
        width: 95%;
        margin: 25px auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .timeline-left {
        width: 100%;
    }
    .timeline-right {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        margin-top: 25px;
    }
    .timeline-right img {
        display: block;
        margin: 12px 0;
        width: 47.5%;
        max-width: 50%;
        min-width: 47%;
        height: auto;
        font-size: 20px;
        margin-right: 5px;
        z-index: 99;
    }
    .container-map {
        padding: 15px 0;
    }
    .container-map::before {
        width: 5px;
    }
    .roadmap-section-heading {
        padding-left: 30px;
        font-size: 20px;
    }
    .roadmap-section-heading::before {
        width: 28px;
        height: 28px;
        top: 10%;
        left: -3.2%;
    }
    .container-map li,
    .container-map p {
        padding-left: 30px;
        font-size: 16px;
    }
}

@media Screen and (max-width: 500px) {
    .roadmap-swim-img.bottom-im {
        left: -150px;
        bottom: -70px;
    }
    .roadmap-swim-img.bottom-im img {
        max-width: 280px
    }
    #roadmap {
        padding-bottom: 90px;
    }
    .big_dolphin {
        top: unset;
        bottom: -10%;
        left: 50%;
        max-width: 300px;
    }
}

@media Screen and (max-width: 450px) {
    .roadmap-section-heading::before {
        left: -3%;
        width: 21px;
        height: 21px;
    }
}

@media Screen and (max-width: 370px) {
    .roadmap-section-heading::before {
        left: -3.5%;
    }
}

@media Screen and (max-width: 310px) {
    .roadmap-swim-img.bottom-im {
        display: none;
    }
    .roadmap-section-heading::before {
        left: -4.2%;
    }
}